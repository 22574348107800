import { Component, computed, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    tap
} from 'rxjs';
import { CategorySearchHit, SearchCategoriesGQL } from '../../../../../../graphql/generated';
import { NgIcon } from '@ng-icons/core';
import { FormsModule } from '@angular/forms';
import { AutoAnimateDirective, SpinnerComponent } from '@knalgeel/pandora';
import { SearchDropdownComponent } from '../search-dropdown/search-dropdown.component';
import { SearchService } from '../../../../search/services/search.service';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { Router } from '@angular/router';
import { AutocompleteOffDirective } from '../../../directives/autocomplete-off.directive';

@Component({
    selector: 'nav-search-supply-category-select',
    standalone: true,
    imports: [
        NgIcon,
        FormsModule,
        AutoAnimateDirective,
        SpinnerComponent,
        SearchDropdownComponent,
        ClickOutsideDirective,
        AutocompleteOffDirective
    ],
    templateUrl: './search-category-select.component.html'
})
export class SearchCategorySelectComponent implements OnInit {

    @ViewChild('input') inputRef!: ElementRef<HTMLInputElement>;

    history: CategorySearchHit[] = this.getHistoryFromLocalStorage();

    // ----------[ State ]----------

    public readonly active = signal<boolean>(false);
    protected readonly active$ = toObservable(this.active);

    protected readonly loading = signal<boolean>(false);

    protected readonly showHistory = signal<boolean>(false);

    protected readonly search = signal<string>('');
    protected readonly search$ = toObservable(this.search);

    protected readonly selectedOption = signal<CategorySearchHit | null>(null);

    // ----------[ Computed ]----------
    protected readonly placeholder = computed(() => this.selectedOption() ? this.selectedOption()!.category!.name : 'Search for a category');
    protected readonly result = toSignal(this.search$
        .pipe(
            debounceTime(100),
            tap(() => this.loading.set(true)),
            switchMap(search =>
                this.searchCategoriesGQL.watch({ input: { term: search } })
                    .valueChanges
                    .pipe(
                        map(result => result.data.searchCategories),
                        catchError(() => [])
                    )
            ),
            tap(() => this.loading.set(false))
        )
    );
    protected readonly categories = computed<CategorySearchHit[]>(
        () => this.result()?.hits as CategorySearchHit[] || []
    )

    // ----------[ Observables ]----------
    protected readonly category$ = toObservable(this.searchService.category);

    // ----------[ Dependencies ]----------
    protected readonly close = close;

    // ----------[ Lifecycle Hooks ]----------

    constructor(
        private readonly searchService: SearchService,
        private readonly router: Router,
        private readonly searchCategoriesGQL: SearchCategoriesGQL
    ) {
    }

    public ngOnInit(): void {
        this.category$
            .pipe(
                take(1)
            )
            .subscribe(category => {
                if (category && !this.selectedOption()) {
                    this.onSearchChange(category.name);
                } else if (!category) {
                    this.selectedOption.set(null);
                }
            })

        this.active$
            .pipe(
                distinctUntilChanged(),
                filter(Boolean)
            )
            .subscribe(() => {
                this.showHistory.set(true);
            })

        this.search$.subscribe((search) => {
            this.showHistory.set(search.length === 0);
        })
    }

    public toggleActive() {
        this.active.update(active => !active);
    }

    // ----------[ Template Methods ]----------

    public focusInput() {
        this.inputRef.nativeElement.focus();
    }

    // TODO: Implement on focusout
    onFocusOut() {
        setTimeout(() => {
            this.active.set(false);
        }, 10);
    }

    onClickOutside() {
        this.active.set(false);
    }

    protected onSearchChange(search: string) {
        this.search.set(search);
        if (search === '') {
            this.selectedOption.set(null);
            this.searchService.set('categoryId', null);
        }
    }

    // ----------[ Api ]----------

    protected onClickListItem(option: CategorySearchHit, event: MouseEvent) {
        this.selectOption(option);
    }

    protected onFocus() {
        this.active.set(true);

        // remove the search term
        this.search.set('');

    }

    protected selectOption(option: CategorySearchHit) {
        this.selectedOption.set(option);
        this.search.set(option.category!.name);
        this.active.set(false);

        // Update category, which will automatically clear any existing segment
        // this.searchService.setCategory(option.category.id);

        this.router.navigate(['/', 'aanbod'], {
            queryParams: { category: option.category!.id },
            queryParamsHandling: 'merge',
            replaceUrl: true
        });

        // Remove the selected option from the history if it exists
        const filteredHistory = this.history.filter(i => i.category!.id !== option.category!.id);

        // Add the selected option to the beginning of the history
        filteredHistory.unshift(option);

        // Update the history, limiting it to 5 items
        this.history = this.removeDuplicatesFromHistory(filteredHistory);

        localStorage.setItem('history', JSON.stringify(this.history));
    }

    // ----------[ Methods ]----------
    private getHistoryFromLocalStorage(): CategorySearchHit[] {
        const storage = localStorage.getItem('history');

        if (storage) {
            const history = JSON.parse(storage) as CategorySearchHit[];
            return this.removeDuplicatesFromHistory(
                history.filter(item => !!item.matches?.name).map(i => {
                    i.listedItemCount = 1; // Make sure the item is selectable
                    return i;
                })
            );
        }

        return [];
    }

    private removeDuplicatesFromHistory(history: CategorySearchHit[]): CategorySearchHit[] {
        const uniqueHistory: CategorySearchHit[] = [];
        const seenIds = new Set<string>();

        for (const item of history) {
            if (!seenIds.has(item.category!.id)) {
                uniqueHistory.push(item);
                seenIds.add(item.category!.id);
            }
        }

        return uniqueHistory.slice(0, 5);
    }
}
