import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OnboardingStep } from './onboarding-step';
import {
    CompanyOnboardingCompleteMediaGQL,
    CompanyOnboardingFragment,
    CompanyOnboardingSetMediaGQL,
    MediaStepFragment
} from '../../../../../graphql/generated';
import { map, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyOnboardingService } from '../company-onboarding.service';

@Injectable({ providedIn: 'root' })
export class MediaStep implements OnboardingStep {
    stepIndex = 1;
    form!: FormGroup;
    isValid = new BehaviorSubject<boolean>(true);

    constructor(
        private fb: FormBuilder,
        private onboardingService: CompanyOnboardingService,
        private setMedia: CompanyOnboardingSetMediaGQL,
        private completeMedia: CompanyOnboardingCompleteMediaGQL
    ) {
        this.initForm();
    }

    initForm(): void {
        this.form = this.fb.group({
            logoId: [ null ],
            headerId: [ null ]
        });

        this.form.statusChanges.subscribe(status => {
            this.isValid.next(status === 'VALID');
        });
    }

    syncFormWithOnboarding(onboarding: CompanyOnboardingFragment): void {
        const step = onboarding?.steps?.[this.stepIndex] as MediaStepFragment;

        if (onboarding) {
            this.form.patchValue({
                logoId: step?.logoId || null,
                headerId: step?.headerId || null
            }, { emitEvent: false });
        }
    }

    set(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Media step is not valid'));
        }

        return this.setMedia.mutate({
            input: {
                companyOnboardingId: onboardingId,
                logoId: this.form.get('logoId')?.value || null,
                headerId: this.form.get('headerId')?.value || null
            }
        }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingSetMedia.companyOnboarding;
                if (!onboarding) {
                    throw new Error('Failed to set media');
                }
                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            }),
            tap(onboarding => {
                this.isValid.next(onboarding.steps?.[this.stepIndex]?.isValid || false);
            })
        );
    }

    complete(onboardingId: string): Observable<CompanyOnboardingFragment> {
        if (!this.isValid.value) {
            return throwError(() => new Error('Media step is not valid'));
        } else {
            console.log('Media step is valid');
        }

        return this.completeMedia.mutate({ companyOnboardingId: onboardingId }).pipe(
            map(result => {
                const onboarding = result.data?.companyOnboardingCompleteMedia.companyOnboarding;
                if (!onboarding) {
                    throw new Error('Failed to complete media step');
                } else {
                    console.log('Completed media step');
                }
                console.log('Completed media step onboarding:', onboarding)

                return onboarding;
            }),
            tap(onboarding => {
                this.onboardingService.updateOnboarding(onboarding);
            })
        );
    }
}
