import {
    AfterViewInit,
    Component,
    computed, Injector,
    input,
    OnDestroy,
    OnInit,
    output,
    runInInjectionContext,
    signal,
    ViewChild
} from '@angular/core';
import { NgIcon } from '@ng-icons/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgClass } from '@angular/common';
import {
    SupplyResultTableItemComponent
} from './result-table-item/supply-result-table-item.component';
import { filter, Observable, Subscription } from 'rxjs';
import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import { toObservable } from '@angular/core/rxjs-interop';
import { AutoAnimateDirective } from '@knalgeel/pandora';
import { Table, TableModule, TableRowSelectEvent, TableRowUnSelectEvent } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { CurrentItemService } from '../../../services/current-item.service';
import { SortEvent } from 'primeng/api';
import { SearchResultNodeFragment } from '../../../../../../graphql/generated';


@Component({
    selector: 'app-supply-result-table',
    standalone: true,
    imports: [
        NgIcon,
        RouterLink,
        NgClass,
        SupplyResultTableItemComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        AsyncPipe,
        AutoAnimateDirective,
        TableModule,
        ProgressBarModule
    ],
    templateUrl: './supply-result-table.component.html',
    styleUrl: './supply-result-table.component.scss'
})
export class SupplyResultTableComponent implements AfterViewInit, OnDestroy {

    @ViewChild('table') table!: Table;

    protected readonly subscriptions: Subscription[] = [];

    // ----------[ Input ]----------

    public readonly hits = input<SearchResultNodeFragment[]>([]);
    public readonly loading = input<boolean>(false);

    // ----------[ State ]----------

    public readonly selectedItem = signal<SearchResultNodeFragment | null>(null);
    public readonly innerSelectedItemId = signal<string | null>(null);
    public readonly sortField = signal<string | null>(null);
    public readonly sortOrder = signal<1 | -1 | 0>(0);
    // ----------[ Output ]----------

    public readonly resultSelected = output<SearchResultNodeFragment | null>();

    // ----------[ Computed ]----------

    primaryResults = computed(() => this.hits().filter(i => i.allFiltersMatched === true || i.allFiltersMatched === null));
    secondaryResults = computed(() => this.hits().filter(i => i.allFiltersMatched === false));

    primaryResults$: Observable<SearchResultNodeFragment[]> = toObservable(this.primaryResults);
    secondaryResults$: Observable<SearchResultNodeFragment[]> = toObservable(this.secondaryResults);

    combinedResults = computed(() => [ ...this.primaryResults(), ...this.secondaryResults() ]);

    // ----------[ TrackBy ]----------

    trackHitByItemId(index: number, item: SearchResultNodeFragment) {
        return item.item!.id;
    }

    // ----------[ Constructor ]----------

    constructor(
        private readonly currentItemService: CurrentItemService,
        private readonly injector: Injector
    ) {
    }

    // ----------[ Event Handlers ]----------

    protected onRowSelect(event: TableRowSelectEvent) {
        this.resultSelected.emit(event.data);
        this.innerSelectedItemId.set(event.data.item.id);
    }

    protected onRowUnselect(event: TableRowUnSelectEvent) {
        this.resultSelected.emit(null);
        this.innerSelectedItemId.set(null);
    }

    // ----------[ Api ]----------

    public scrollToTop() {
        this.table.scroller!.scrollToIndex(0);
    }

    // ----------[ Lifecycle Hooks ]----------

    public ngAfterViewInit() {
        runInInjectionContext(this.injector, () => {
            this.subscriptions.push(
                this.currentItemService.item$
                    .subscribe((item) => {
                        if (!item) {
                            this.selectedItem.set(null);
                            this.innerSelectedItemId.set(null);
                            return;
                        }

                        const index = this.table.value.findIndex(i => i.item.id === item.id);

                        this.selectedItem.set(index !== -1 ? this.table.value[index] : null);

                        if (index !== -1 && this.innerSelectedItemId() !== item.id) {
                            setTimeout(() => this.table.scroller!.scrollToIndex(index - 2, 'smooth'), 0);
                        }
                    })
            );
        });

    }

    protected customSort(event: SortEvent) {
        const field = event.field;
        const order = event.order ?? 1;

        this.sortField.set(field ?? null);
        this.sortOrder.set(order as 1 | -1 | 0);

        event.data?.sort((data1, data2) => {
            let value1 = this.getNestedProperty(data1, field!);
            let value2 = this.getNestedProperty(data2, field!);

            if (value1 == null && value2 != null) return -1 * order;
            if (value1 != null && value2 == null) return order;
            if (value1 == null && value2 == null) return 0;

            if (field === 'searchParameters.date') {
                value1 = new Date(value1 as string).getTime();
                value2 = new Date(value2 as string).getTime();
            }

            if (value1 < value2) return -1 * order;
            if (value1 > value2) return order;
            return 0;
        });
    }

    // ----------[ Helper Methods ]----------

    private getNestedProperty(obj: any, path: string): any {
        return path.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : null, obj);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
