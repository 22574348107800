
import { Component, OnInit } from '@angular/core';
import { Footer } from '../../core/shell/footer/footer';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'page-manual',
    standalone: true,
    imports: [
        Footer
    ],
    templateUrl: './manual.component.html'
})
export class ManualComponent implements OnInit {
    safePdfUrl!: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdf/handleiding-planmeister.pdf');
    }
}
