import { computed, DestroyRef, Injectable, signal } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';
import { OnboardingWrapperComponent } from './wrapper/onboarding-wrapper.component';
import { createMachine, interpret } from '@xstate/fsm';
import {
    CompanyOnboardingFragment,
    GetCompanyOnboardingByIdGQL,
    InitiateCompanyOnboardingGQL
} from '../../../../graphql/generated';
import { catchError, EMPTY, Observable, Subject, takeUntil, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

type OnboardingContext = {
    onboarding: CompanyOnboardingFragment | null;
}

type OnboardingEvent =
    | { type: 'INITIATE' }
    | { type: 'NEXT' }
    | { type: 'PREVIOUS' }
    | { type: 'COMPLETE' }
    | { type: 'RESET' }
    | { type: 'UPDATE_ONBOARDING'; onboarding: CompanyOnboardingFragment | null }

export enum OnboardingStep {
    NotStarted = 'notStarted',
    BasicInformation = 'basicInformation',
    ContactInformation = 'contactInformation',
    Location = 'location',
    Media = 'media',
    User = 'user',
    Completed = 'completed'
}

@Injectable({
    providedIn: 'root'
})
export class CompanyOnboardingService {
    private readonly destroy = new Subject<void>();
    private isDestroyed = false;

    private machine = createMachine<OnboardingContext, OnboardingEvent>({
        id: 'onboarding',
        initial: OnboardingStep.NotStarted,
        context: {
            onboarding: null
        },
        states: {
            [OnboardingStep.NotStarted]: {
                on: {
                    INITIATE: OnboardingStep.BasicInformation,
                    UPDATE_ONBOARDING: [
                        {
                            target: OnboardingStep.BasicInformation,
                            cond: (_, event) => !!event.onboarding
                        },
                        {
                            target: OnboardingStep.NotStarted
                        }
                    ]
                }
            },
            [OnboardingStep.BasicInformation]: {
                on: {
                    NEXT: OnboardingStep.ContactInformation,
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: {
                        target: OnboardingStep.ContactInformation,
                        actions: [ 'updateContext' ],
                        cond: (_, event) => this.isStepComplete(event.onboarding, 'BasicInformationStep')
                    }
                }
            },
            [OnboardingStep.ContactInformation]: {
                on: {
                    NEXT: OnboardingStep.Location,
                    PREVIOUS: OnboardingStep.BasicInformation,
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: {
                        target: OnboardingStep.Location,
                        actions: [ 'updateContext' ],
                        cond: (_, event) => this.isStepComplete(event.onboarding, 'ContactInformationStep')
                    }
                }
            },
            [OnboardingStep.Location]: {
                on: {
                    NEXT: OnboardingStep.Media,
                    PREVIOUS: OnboardingStep.ContactInformation,
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: {
                        target: OnboardingStep.Media,
                        actions: [ 'updateContext' ],
                        cond: (_, event) => this.isStepComplete(event.onboarding, 'LocationStep')
                    }
                }
            },
            [OnboardingStep.Media]: {
                on: {
                    NEXT: OnboardingStep.User,
                    PREVIOUS: OnboardingStep.Location,
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: {
                        target: OnboardingStep.User,
                        actions: [ 'updateContext' ],
                        cond: (_, event) => this.isStepComplete(event.onboarding, 'MediaStep')
                    }
                }
            },
            [OnboardingStep.User]: {
                on: {
                    NEXT: OnboardingStep.Completed,
                    PREVIOUS: OnboardingStep.Media,
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: {
                        target: OnboardingStep.Completed,
                        actions: [ 'updateContext' ],
                        cond: (_, event) => this.isStepComplete(event.onboarding, 'UserStep')
                    }
                }
            },
            [OnboardingStep.Completed]: {
                on: {
                    RESET: OnboardingStep.NotStarted,
                    UPDATE_ONBOARDING: OnboardingStep.Completed
                }
            }
        }
    }, {
        actions: {
            updateContext: (context, event) => {
                if (event.type === 'UPDATE_ONBOARDING') {
                    // console.log('Updating onboarding context:', event.onboarding);
                    context.onboarding = event.onboarding;
                }
            }
        }
    });

    private service = interpret(this.machine).start();

    public readonly currentOnboardingId = computed(() => this.getCurrentOnboardingId());
    public readonly currentOnboardingId$ = toObservable(this.currentOnboardingId);
    public readonly isLoading = signal<boolean>(false);
    public readonly currentStep = signal<OnboardingStep>(OnboardingStep.NotStarted);

    public readonly currentStepIndex = computed(() => {
        return this.getFirstIncompleteStepIndex(this.onboarding());
    });

    public readonly error = signal<string | null>(null);
    public readonly onboarding = signal<CompanyOnboardingFragment | null>(null);
    public readonly onboarding$ = toObservable(this.onboarding);

    constructor(
        private readonly destroyRef: DestroyRef,
        private readonly modalService: ModalService,
        private readonly getOnboardingById: GetCompanyOnboardingByIdGQL,
        private readonly initiateOnboarding: InitiateCompanyOnboardingGQL
    ) {
        this.initializeSubscriptions();

        this.service.subscribe(state => {
            // console.log('Onboarding service state:', state.value);
            // console.log('Onboarding service context:', state.context);
            this.currentStep.set(state.value as OnboardingStep);
            this.onboarding.set(state.context.onboarding);
        });

        this.destroyRef.onDestroy(() => {
            this.onDestroy();
        });
    }

    private initializeSubscriptions(): void {
        this.currentOnboardingId$
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.updateCurrentOnboarding());
    }

    public start(): void {
        this.modalService.open({
            component: OnboardingWrapperComponent,
            inputs: {},
            options: {
                closeOnBackdropClick: false,
                size: 'screen'
            }
        });
    }

    public initiateOnboardingProcess(
        chamberOfCommerceNumber?: string,
        domain?: string
    ): Observable<CompanyOnboardingFragment> {
        this.isLoading.set(true);
        this.error.set(null);

        let input = {};

        if (chamberOfCommerceNumber) {
            input = { ...input, chamberOfCommerceNumber };
        }

        if (domain) {
            input = { ...input, domain };
        }

        return this.initiateOnboarding.mutate({
            input
        }).pipe(
            tap(({ data }) => {
                if (data?.initiateCompanyOnboarding) {
                    this.setCurrentOnboardingId(data.initiateCompanyOnboarding.companyOnboarding.id);
                    this.updateOnboarding(data.initiateCompanyOnboarding.companyOnboarding);
                }
            }),
            catchError(err => {
                this.error.set('Failed to initiate onboarding process');
                console.error('Initiate onboarding error:', err);
                return EMPTY;
            }),
            tap(() => this.isLoading.set(false))
        ) as Observable<CompanyOnboardingFragment>;
    }

    public setCurrentOnboardingId(id: string): void {
        localStorage.setItem('onboardingId', id);
        this.updateCurrentOnboarding();
    }

    public getCurrentOnboardingId(): string | null {
        return localStorage.getItem('onboardingId');
    }

    public clearCurrentOnboardingId(): void {
        localStorage.removeItem('onboardingId');
        this.updateOnboarding(null);
    }

    private updateCurrentOnboarding(): void {
        const onboardingId = this.getCurrentOnboardingId();

        // console.log('Current onboarding ID:', onboardingId);

        if (onboardingId) {
            this.isLoading.set(true);
            this.getOnboardingById.watch({ onboardingId })
                .valueChanges
                .pipe(
                    takeUntil(this.destroy),
                    catchError(err => {
                        this.error.set('Failed to fetch onboarding data');
                        console.error('Fetch onboarding error:', err);
                        return EMPTY;
                    })
                )
                .subscribe(({ data }) => {
                    if (data?.companyOnboardingById) {
                        // console.log('Fetched onboarding data:', data.companyOnboardingById);
                        this.updateOnboarding(data.companyOnboardingById);
                    } else {
                        this.updateOnboarding(null);
                    }
                    this.isLoading.set(false);
                });
        } else {
            this.updateOnboarding(null);
        }
    }

    public updateOnboarding(onboarding: CompanyOnboardingFragment | null): void {
        this.service.send({ type: 'UPDATE_ONBOARDING', onboarding });
        this.onboarding.set(onboarding);
    }

    public previousStep(): void {
        this.service.send('PREVIOUS');
    }

    public resetOnboarding(): void {
        this.service.send('RESET');
        this.clearCurrentOnboardingId();
    }

    public cancel(): void {
        this.resetOnboarding();
        this.modalService.close();
    }

    public complete(): void{
        this.service.send('RESET');
        this.clearCurrentOnboardingId();

        this.modalService.close();
    }

    public resumeLater(): void {
        this.modalService.close();
    }

    private getFirstIncompleteStepIndex(onboarding: CompanyOnboardingFragment | null): number {
        if (!onboarding || !onboarding.steps) return 0;

        const stepOrder = [
            'BasicInformationStep',
            'ContactInformationStep',
            'LocationStep',
            'MediaStep',
            'UserStep'
        ];

        for (let i = 0; i < stepOrder.length; i++) {
            const step = onboarding.steps.find(s => s?.__typename === stepOrder[i]);
            if (!step || !step.isComplete) {
                return i;
            }
        }

        return stepOrder.length; // All steps are complete
    }

    private isStepComplete(onboarding: CompanyOnboardingFragment | null, stepName: string): boolean {
        if (!onboarding || !onboarding.steps) return false;

        if (stepName === 'UserStep') {
            return onboarding.steps.every(step => step?.isComplete);
        }

        const step = onboarding.steps.find(step => step!.__typename === stepName);
        return step ? step.isComplete : false;
    }

    private onDestroy(): void {
        if (this.isDestroyed) return;
        this.isDestroyed = true;
        this.destroy.next();
        this.destroy.complete();
        // console.log('OnboardingService destroyed');
    }
}
