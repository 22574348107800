import {AfterViewInit, Component, computed, ElementRef, model, signal, ViewChild} from '@angular/core';
import { NgIcon } from "@ng-icons/core";
import { PredefinedValueProperty, Property, PropertyFilterInput } from "../../../../../../graphql/generated";
import { SearchService } from "../../../services/search.service";
import { AutoAnimateDirective } from "@knalgeel/pandora";

interface ActiveFilterItem {
    id?: string;
    type: keyof PropertyFilterInput
    property: Property;
    label: string;
}

@Component({
    selector: 'app-search-supply-active-filters',
    standalone: true,
    imports: [
        NgIcon,
        AutoAnimateDirective
    ],
    templateUrl: './search-supply-active-filters.component.html',
    styleUrl: './search-supply-active-filters.component.scss'
})
export class SearchSupplyActiveFiltersComponent implements AfterViewInit {
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;

    ngAfterViewInit() {
        this.setupHorizontalScroll();
    }

    private setupHorizontalScroll() {
        const scrollElement = this.scrollContainer.nativeElement;

        scrollElement.addEventListener('wheel', (e: WheelEvent) => {
            if (e.deltaY == 0) return;
            e.preventDefault();
            scrollElement.scrollLeft += e.deltaY + e.deltaX;
        });
    }

    // ----------[ Computed ]----------

    protected readonly properties = computed(() => this.searchService.category()?.properties || []);

    protected readonly filters = this.searchService.select('filters');

    protected readonly filterItems = computed<ActiveFilterItem[]>(() => {
        const filters = this.filters();

        const items = [] as any[];

        filters.forEach(filter => {
            const type = this.getFilterType(filter);
            const property = this.getPropertyById(filter[type]?.propertyId);

            if (property === undefined) {
                return;
            }

            switch (type) {
                case "numeric":
                    items.push({
                        id: filter.numeric?.propertyId,
                        type,
                        property,
                        label: `${ property.name } ${ filter.numeric?.minNumericValue } - ${ filter.numeric?.maxNumericValue }`
                    });
                    break;
                case "predefined":
                    const _property = property as PredefinedValueProperty;
                    filter.predefined?.predefinedValueIds.forEach(id => {
                        items.push({
                            id,
                            type,
                            property: _property,
                            label: _property!.values!.find(value => value!.id === id)?.name
                        });
                    })
                    break;
                case "boolean":
                    break;
            }
        })

        return items;
    });

    // ----------[ Input ]----------

    public readonly active = model<boolean>(false);

    // ----------[ Dependencies ]----------

    constructor(
        private readonly searchService: SearchService
    ) {}

    // ----------[ Template Methods ]----------

    protected toggleFilterMenu() {
        this.active.update(active => ! active);
    }

    protected removeFilterValue(filter: ActiveFilterItem) {
        switch (filter.type) {
            case "predefined":
                this.searchService.removePredefinedFilterValue(filter.property.id, filter.id as string);
                break;
            case "numeric":
                this.searchService.removeFilter(filter.property.id);
                break;
        }
    }

    // ----------[ Helpers ]----------

    protected getPropertyById(id: string): Property {
        return this.properties().find(property => property.id === id) as Property;
    }

    protected getFilterType(property: PropertyFilterInput): keyof PropertyFilterInput {
        return Object.keys(property)[0] as keyof PropertyFilterInput;
    }
}
