import { computed, Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { User } from '../types/user';
import { Ability } from '../types/ability';
import { CompanyService } from '../../company/services/company.service';
import {
    EmployeeByUserIdGQL,
    EmployeeByUserIdQuery,
    SubscriptionStatus
} from '../../../../graphql/generated';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public static readonly ACCESS_TOKEN_KEY = 'access_token';
    private static readonly ADMIN_ACCESS_TOKEN_KEY = 'admin_access_token';
    private static readonly AUTH_HEADER = 'Authorization';

    public currentUser = signal<User | null>(null);
    public currentEmployee = signal<EmployeeByUserIdQuery['employeeByUserId'] | null>(null);
    public isImposter = signal<boolean>(false);
    public isAuthenticated = computed(() => !!this.currentUser());
    public isLoading = signal<boolean>(false);

    constructor(
        private http: HttpClient,
        private router: Router,
        private employeeByUserId: EmployeeByUserIdGQL,
        private companyService: CompanyService
    ) {
        this.initializeUser();
        this.initializeImpersonation();
    }

    private initializeUser(): void {
        const token = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
        if (token) {
            this.setAuthToken(token);
            this.initializeProfile().pipe(
                catchError((error) => {
                    console.error('Error during user initialization:', error);
                    this.signOut();
                    this.router.navigate([ '/login' ], { queryParams: { error: 'init_failed' } });
                    return throwError(() => error);
                })
            ).subscribe();
        }
    }

    private initializeImpersonation(): void {
        const token = localStorage.getItem(AuthService.ADMIN_ACCESS_TOKEN_KEY);
        if (token) {
            this.isImposter.set(true);
        }
    }

    private setAuthToken(token: string): void {
        localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, token);
    }

    private getAuthHeaders(): HttpHeaders {
        const token = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
        return new HttpHeaders().set(AuthService.AUTH_HEADER, `Bearer ${token}`);
    }

    hasToken(): boolean {
        return !!localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
    }

    initializeProfile(): Observable<User> {
        this.isLoading.set(true);

        return this.fetchUserProfile().pipe(
            switchMap(user => this.fetchAndSetEmployeeData(user)),
            tap(user => this.currentUser.set(user)),
            catchError(this.handleProfileError),
            finalize(() => this.isLoading.set(false))
        );
    }

    private fetchUserProfile(): Observable<User> {
        return this.http.get<{
            data: User
        }>(`${environment.deprecatedApi.apiUrl}/api/v1/profile`, { headers: this.getAuthHeaders() })
            .pipe(
                map(response => response.data),
                catchError(() => throwError(() => new Error('Profile not found')))
            );
    }

    private fetchAndSetEmployeeData(user: User): Observable<User> {
        return this.employeeByUserId.fetch({ userId: user.id }).pipe(
            tap(({ data }) => {
                if (!data.employeeByUserId) {
                    throw new Error('Employee not found');
                }
                this.currentEmployee.set(data.employeeByUserId);
                this.companyService.set('companyId', data.employeeByUserId.companyId);
            }),
            map(() => user)
        );
    }

    private handleProfileError = (error: any): Observable<never> => {
        if (error.status === 403) {
            this.router.navigate([ '/403' ]);
        } else {
            localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
            localStorage.removeItem(AuthService.ADMIN_ACCESS_TOKEN_KEY);
            this.router.navigate([ '/' ]);
        }
        return throwError(() => error);
    }

    signIn(email: string, password: string): Observable<User | null> {
        this.isLoading.set(true);
        return this.http.post<any>(`${environment.deprecatedApi.apiUrl}/oauth/token`, {
            grant_type: 'password',
            client_id: environment.deprecatedApi.oauthClientId,
            client_secret: environment.deprecatedApi.oauthClientSecret,
            username: email,
            password: password
        }).pipe(
            tap(({ access_token }) => {
                this.setAuthToken(access_token);
            }),
            switchMap(() => this.initializeProfile()),
            catchError(error => throwError(() => error)),
            finalize(() => this.isLoading.set(false))
        );
    }

    signOut(): void {
        localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
        localStorage.removeItem(AuthService.ADMIN_ACCESS_TOKEN_KEY);
        this.currentUser.set(null);
        this.currentEmployee.set(null);
        this.isImposter.set(false);
        this.router.navigate([ '/' ]);
    }

    impersonateUser(userId: string): Observable<any> {
        if (this.isImposter()) {
            return this.leaveImpersonation().pipe(
                switchMap(() => this.impersonateUser(userId))
            );
        }

        return this.http.post<any>(`${environment.deprecatedApi.apiUrl}/api/v1/admin/users/${userId}/impersonate`, {}, { headers: this.getAuthHeaders() })
            .pipe(
                tap(({ access_token }) => {
                    const currentToken = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
                    if (currentToken) {
                        localStorage.setItem(AuthService.ADMIN_ACCESS_TOKEN_KEY, currentToken);
                        this.setAuthToken(access_token);
                        this.isImposter.set(true);
                        this.initializeProfile().subscribe();
                    }
                })
            );
    }

    leaveImpersonation(): Observable<boolean> {
        const adminToken = localStorage.getItem(AuthService.ADMIN_ACCESS_TOKEN_KEY);

        if (!adminToken) {
            return of(false);
        }

        return new Observable<boolean>(observer => {
            try {
                this.setAuthToken(adminToken);
                localStorage.removeItem(AuthService.ADMIN_ACCESS_TOKEN_KEY);
                this.isImposter.set(false);

                observer.next(true);
            } catch (error) {
                observer.error(error);
            }
        }).pipe(
            tap(() => this.initializeProfile()),
            tap(() => console.log('Successfully left impersonation')),
            catchError(error => {
                console.error('Error leaving impersonation:', error);
                return throwError(() => new Error('Failed to leave impersonation'));
            })
        );
    }

    hasAbility = computed(() => (requiredAbility: Partial<Ability>): boolean => {
        const user = this.currentUser();
        if (!user?.abilities) return false;

        return user.abilities.some(userAbility => this.abilityMatches(userAbility, requiredAbility));
    });

    private abilityMatches(userAbility: Ability, requiredAbility: Partial<Ability>): boolean {
        if (requiredAbility.permission && userAbility.permission !== requiredAbility.permission) {
            return false;
        }
        return !(requiredAbility.companySlug && userAbility.companySlug !== requiredAbility.companySlug);
    }

    canManage = computed(() => {
        return this.isAuthenticated();
    });

    hasSubscriptionAccess = computed(() => {
        const company = this.currentEmployee()?.company;
        if(!this.currentEmployee() || !company) {
            return false;
        }

        const status = this.currentEmployee()!.company!.subscription?.status;

        return status === null
            || status === undefined
            || status === SubscriptionStatus.Active
            || status === SubscriptionStatus.Trial;
    });

    resetPassword(token: string, email:string, password: string, passwordConfirmation:string): Observable<void> {
        return this.http.post<void>(`${environment.deprecatedApi.apiUrl}/api/v1/reset-password`, { token, email, password, passwordConfirmation });
    }
    forgotPassword(email:string): Observable<void> {
        return this.http.post<void>(`https://api.planmeister.knalgeel.io/api/v1/forgot-password`, {email});
    }
}
