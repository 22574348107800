import { Component, effect, inject, input, OnDestroy, OnInit, signal } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors, ValidatorFn
} from '@angular/forms';
import { DatePipe, NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

import {
    CompanyByIdDocument,
    Item,
    ListItemGQL,
    Location,
    LocationInput
} from '../../../../../../../../graphql/generated';
import { ModalService } from '../../../../../../core/services/modal.service';
import {
    SearchDropdownComponent
} from '../../../../../../core/components/inputs/search-dropdown/search-dropdown.component';
import { AddressLookupService } from '../../../../../../core/services/address-lookup.service';
import {
    AddressSearchDropdownComponent
} from '../../../../../../core/components/inputs/address-search-dropdown/address-search-dropdown.component';
import {
    DateDropdownComponent
} from '../../../../../../core/components/inputs/date-dropdown/date-dropdown.component';
import { DateTimeService } from '../../../../../../core/services/date-time.service';

type mode = 'renew' | 'list';

@Component({
    selector: 'company-manage-supply-list-item-modal',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        DatePipe,
        NgIf,
        SearchDropdownComponent,
        AddressSearchDropdownComponent,
        DateDropdownComponent
    ],
    templateUrl: './list-item-modal.component.html'
})
export class ListItemModalComponent implements OnInit, OnDestroy {
    item = input<Item | null>(null);

    mode = input<mode>('list');

    loading = signal(false);
    addressSuggestions = signal<LocationInput[]>([]);
    selectedAddressSuggestion = signal<LocationInput | null>(null);

    listItemForm: FormGroup;

    private subscriptions: Subscription[] = [];

    private fb = inject(FormBuilder);
    private listItemGQL = inject(ListItemGQL);
    private modalService = inject(ModalService);
    private addressLookupService = inject(AddressLookupService);
    private dateTimeService = inject(DateTimeService);

    constructor() {
        this.listItemForm = this.fb.group({
            startDate: [null, [this.optionalFutureDateValidator()]],
            endDate: [null, [this.optionalFutureDateValidator()]],
            location: [null],
            where: [null]
        }, {
            validators: [this.dateRangeValidator()]
        });


        effect(() => {
            const item = this.item();
            if (item) {
                this.listItemForm.patchValue({
                    location: this.locationToLocationInput(item.location)
                });
            }
        });
    }

    ngOnInit() {
        this.setupFormSubscriptions();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    listItem() {
        if (this.listItemForm.invalid) {
            console.error('Form is invalid:', this.listItemForm.errors);
            return;
        }

        this.loading.set(true);

        const availability = this.dateTimeService.formatDateRange(this.listItemForm.get('startDate')?.value, this.listItemForm.get('endDate')?.value);

        this.listItemGQL.mutate(
            {

                input: {
                    itemId: this.item()?.id,
                    availability: availability,
                    location: this.listItemForm.get('location')?.value || this.locationToLocationInput(this.item()!.location)
                }
            },
            {
                refetchQueries: [ {
                    query: CompanyByIdDocument,
                    variables: { id: this.item()?.company!.id }
                } ]
            }).subscribe({
            next: () => {
                this.loading.set(false);
                this.modalService.close();
            },
            error: (error) => {
                this.loading.set(false);
                console.error('Error listing item', error);
            }
        });
    }

    cancel() {
        this.modalService.close();
    }


    protected readonly mapAddressOption = (option: LocationInput) => option.properties.formattedAddress;

    protected selectAddressSuggestion(suggestion: LocationInput | null) {
        if (!suggestion) {
            return;
        }

        this.listItemForm.patchValue(
            {
                where: suggestion.properties.formattedAddress,
                location: suggestion
            },
            { emitEvent: false }
        );

        this.addressSuggestions.set([]);
        this.selectedAddressSuggestion.set(suggestion);
    }

    isFieldInvalid(fieldName: string): boolean {
        const control = this.listItemForm.get(fieldName);
        return !!control && control.invalid && (control.dirty || control.touched);
    }

    hasError(fieldName: string, errorType: string): boolean {
        const control = this.listItemForm.get(fieldName);
        return !!control && control.hasError(errorType) && (control.dirty || control.touched);
    }

    hasDateRangeError(): boolean {
        return this.listItemForm.hasError('invalidDateRange')
            && (this.listItemForm.get('startDate')?.touched || this.listItemForm.get('endDate')?.touched)
            || false;
    }

    private locationToLocationInput(location: Location): LocationInput {
        return {
            properties: {
                address: {
                    houseNumber: location.properties.address?.houseNumber || '',
                    street: location.properties.address?.street || '',
                    city: location.properties.address?.city || '',
                    country: location.properties.address?.country || '',
                    postcode: location.properties.address?.postcode || ''
                },
                formattedAddress: location.properties.formattedAddress || ''
            },
            geometry: {
                coordinates: location.geometry.coordinates
            }
        };
    }

    private optionalFutureDateValidator(): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            const selectedDate = new Date(control.value);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            return selectedDate < currentDate ? { pastDate: true } : null;
        };
    }

    private dateRangeValidator(): ValidatorFn {
        return (form: AbstractControl): ValidationErrors | null => {
            if (!(form instanceof FormGroup)) {
                return null;
            }

            const startDate = form.get('startDate')?.value;
            const endDate = form.get('endDate')?.value;

            if (!startDate || !endDate) {
                return null;
            }

            const start = new Date(startDate);
            const end = new Date(endDate);

            if (end < start) {
                return { invalidDateRange: true };
            }

            return null;
        };
    }



    private setupFormSubscriptions() {
        this.subscriptions.push(
            this.listItemForm.controls['where'].valueChanges.subscribe(value => {
                if (value === '' || value === null) {
                    this.addressSuggestions.set([]);
                    return;
                }

                this.addressLookupService.lookupAddress(value).subscribe(addresses => {
                    this.addressSuggestions.set(addresses);
                });
            }));
    }


}
