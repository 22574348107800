export const environment = {
    production: true,
    clientGraphQLApi: {
        httpUrl: 'https://soil.planmeister.com/graphql',
        wsUrl: 'wss://soil.planmeister.com/graphql',
    },
    deprecatedApi: {
        apiUrl: 'https://api.planmeister.knalgeel.io',
        oauthClientId: '6851928a-7de7-4024-a94c-bb7111dfd71d',
        oauthClientSecret: 'sgYperqreZhrCBy9tYaHWSKWIBzzoK1a2JglYjrZ'
    }
};
