import { CategoryConfiguration, Item } from '../../../graphql/generated';

export interface InventoryCategory {
    id: string;
    name: string;
    pluralName: string;
    synonyms: string[];
    radius?: number;
    items: Item[];
    order?: number;
}

export class InventoryCategoryBuilder {
    public static buildInventoryCategories(
        items: Item[],
        categoryConfigurations: CategoryConfiguration[]
    ): InventoryCategory[] {
        const categoryMap = new Map<string, InventoryCategory>();

        // First, create categories from items
        items.forEach(item => {
            if (!item.category) {
                return;
            }

            if (!categoryMap.has(item.category.id)) {
                categoryMap.set(item.category.id, {
                    id: item.category.id,
                    name: item.category.name,
                    pluralName: item.category.pluralName,
                    synonyms: item.category.synonyms,
                    radius: item.category.radius ?? undefined,
                    items: []
                });
            }
            categoryMap.get(item.category.id)!.items.push(item);
        });

        // Then, merge with category configurations
        categoryConfigurations.forEach(config => {
            if (categoryMap.has(config.categoryId)) {
                const category = categoryMap.get(config.categoryId)!;
                category.order = config.order ?? undefined;
                category.radius = config.radius ?? category.radius;
            } else {
                categoryMap.set(config.categoryId, {
                    id: config.categoryId,
                    name: '', // We don't have this information from CategoryConfiguration
                    pluralName: '', // We don't have this information from CategoryConfiguration
                    synonyms: [],
                    radius: config.radius ?? undefined,
                    items: [],
                    order: config.order ?? undefined
                });
            }
        });

        return Array.from(categoryMap.values());
    }

    public static orderCategories(categories: InventoryCategory[]): InventoryCategory[] {
        return categories.sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order;
            }
            if (a.order !== undefined) return -1;
            if (b.order !== undefined) return 1;
            return a.name.localeCompare(b.name);
        });
    }
}
